import 'normalize.css';

import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import { Trans } from 'react-i18next';

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import NavContext, { NavProvider } from '../../components/Layout/Nav/NavProvider';

import SectionHeader from '../../components/SectionHeader';
import { getCurrentLanguage } from "../../i18n";
import LegalPage from '../../components/LegalPage';
import LegalSection from '../../components/LegalSection';

const PageTemplate: FC = () => {

  const page: any = {}
  page.i18n_lang = getCurrentLanguage();

  const currentSlug = `/${page.i18n_lang}/aviso-de-confidencialidad`

  const language = page.i18n_lang
  const pageTitle = 'Aviso de confidencialidad Youplanet'
  const description = 'Aviso de confidencialidad Youplanet'

  const { isMobile } = useContext(NavContext);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);
  const section = useRef();


  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  return <NavProvider>
    <Layout
      language={page.i18n_lang}
      isHome={false}
    >
      <SEO
        title={pageTitle}
        description={description}
        lang={language}
        slug={currentSlug}
      />
      <SectionHeader h1={t('noticeOfConfidentiality.title')} inView={true} isTop />

      <LegalPage>
        <LegalSection title={t('noticeOfConfidentiality.title')} arrow>
          <p ref={section}>
          <Trans>
            {t('noticeOfConfidentiality.description')}
          </Trans>
          </p>
        </LegalSection>
      </LegalPage>
    </Layout>
  </NavProvider>
}

export default PageTemplate 
