import './LegalSection.scss';

import React, { FC } from 'react'

export interface DataPanelProps {
  title?: string;
  index?: number;
  arrow?: boolean;
}

const LegalSection: FC<DataPanelProps> = ({ title, index, arrow, children}) => {
  return (
    <div className="legal-section">
      {title &&
        <h2 className="legal-section__title">
          {index && <span className="legal-section__circle">{index}</span>}
          {arrow && <span className="legal-section__circle"><span className="legal-section__arrow"></span></span>}
          {title}
        </h2>
      }      
      <div className="legal-section__content">
        {children}
      </div>
    </div>
  );
};

export default LegalSection 
